import { Logo, SocialIcon } from 'src/components'
import './style.scss'

export const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>

                <div className='footer__container'>

                    <div className='footer__row'>
                        <a href='#start'>
                            <Logo />
                        </a>

                        <a
                            href='https://www.ddxfitness.ru/?utm_source=event&utm_campaign=play_ddx'
                            className='btn btn--light btn--with-icon'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <p>ddxfitness.ru</p>
                            <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M1.5 9L9.5 1M9.5 1H1.5M9.5 1V9' strokeWidth='2' strokeLinecap='round' />
                            </svg>
                        </a>

                        <div className='footer__links'>
                            <a className='link' target='_blank' rel='noreferrer' href='https://vk.com/ddxfitness'>
                                <button className='btn btn--social'>
                                    <SocialIcon icon='vk' />
                                </button>
                            </a>

                            <a className='link' target='_blank' rel='noreferrer' href='https://t.me/ddxfitness_official'>
                                <button className='btn btn--social'>
                                    <SocialIcon icon='tg' />
                                </button>
                            </a>

                            <a className='link' target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg'>
                                <button className='btn btn--social'>
                                    <SocialIcon icon='yt' />
                                </button>
                            </a>

                            <a className='link' target='_blank' rel='noreferrer' href='https://dzen.ru/ddx_fitness_ru'>
                                <button className='btn btn--social'>
                                    <SocialIcon icon='dzen' />
                                </button>
                            </a>
                        </div>
                        
                    </div>

                    <div className='footer__divider'></div>

                    <div className='footer__row'>
                        <p id='rights' className='link'>© 2024 ООО «ИЛОН» | Ilon, LLC</p>
                        <a
                            href='https://www.ddxfitness.ru/policy/?utm_source=event&utm_campaign=play_ddx'
                            target='_blank'
                            rel='noreferrer'
                            className='link'
                        >
                            Соглашение о защите персональных данных
                        </a>
                        <a
                            href='https://www.ddxfitness.ru/terms_of_use/?utm_source=event&utm_campaign=play_ddx'
                            target='_blank'
                            rel='noreferrer'
                            className='link'
                        >
                            Пользовательское соглашение
                        </a>
                    </div>
                </div>
                
            </div>
        </footer>
    )
}